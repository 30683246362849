import type { Issue } from '@/services/generated/client/public/models'
import Cookies from 'js-cookie'
import type { ClickData, FormDataLayer, ItemData, PageData, TransactionData, UserData } from '~/types/dataLayer'
import { DataLayerEventsEnum } from '~/types/dataLayer'

export default defineNuxtPlugin(() => {
  const route = useRoute()
  const { dataLayer } = useScriptGoogleTagManager()

  const userData = useUserData()

  function pushPageView(route: ReturnType<typeof useRoute>): void {
    dataLayer.push({
      event: DataLayerEventsEnum.PageView,
      user_data: userData.value,
      page_data: getPageData(route),
      _clear: true,
    })
  }

  const blockedPageViewEventRoutes = ['/investice']

  watch(route, () => {
    if (!blockedPageViewEventRoutes.some(path => route.path.includes(path))) {
      pushPageView(route)
    }
  })

  function trackEvent({
    $event,
    eventName,
    flowStep,
    formData,
    transactionData,
    itemData,
    pageData,
  }: {
    $event?: Event
    eventName?: DataLayerEventsEnum
    flowStep?: string
    formData?: Record<string, any>
    transactionData?: Record<string, any>
    itemData?: Record<string, any>
    pageData?: PageData
  } = {}) {
    const obj = {
      event: eventName || undefined,
      user_data: userData.value,
      page_data: getPageData(route),
    }
    const buttonTrackId = $event?.currentTarget?.dataset?.buttonTrackId

    Object.assign(obj, {
      ...(flowStep && { flow_step: flowStep }),
      ...(formData && { form_data: getFormDataLayer(formData) }),
      ...(transactionData && { transaction_data: getTransactionData(transactionData) }),
      ...(itemData && { items_data: [getItemData(itemData)] }),
      ...(buttonTrackId && { click_data: getClickData(buttonTrackId) }),
    })
    if (pageData) {
      obj.page_data = getPageData(route, pageData)
    }

    Object.assign(obj, { _clear: true })

    dataLayer.push(obj)
  }

  function useUserData(): ComputedRef <UserData | null> {
    return computed(() => {
      const userState = JSON.parse(Cookies.get('userState') || '{}')
      return {
        user_id: userState?.user_id?.toString() || undefined,
        lead_id: userState?.lead_id?.toString() || undefined,
        name: userState?.name || undefined,
        name_hashed: userState?.name_hashed || undefined,
        phone: userState?.phone || undefined,
        phone_hashed: userState?.phone_hashed || undefined,
        email: userState?.email || undefined,
        email_hashed: userState?.email_hashed || undefined,
        user_address: userState?.address || undefined,
        clv: userState?.portfolio_value?.toString() || undefined,
        category: undefined,
        mkt_segment: undefined,
        legal_limitation: userState?.legal_limitation || undefined,
      }
    })
  }
  function getPageData(route: ReturnType<typeof useRoute>, data?: PageData): PageData | undefined {
    const config = useRuntimeConfig()
    if (!window)
      return
    return {
      location: window.location.href,
      title: formatString(document.title),
      category: formatString(route.meta.category as undefined | string) as string | undefined,
      language: route.meta.language as string | undefined ?? navigator.language,
      is_virtual: data?.is_virtual as boolean | undefined ?? false,
      virtual_location: formatString(data?.virtual_location) as string | undefined,
      environment: config.public.environment,
      locale: 'web~public',
      author: formatString(route.meta.author as undefined | string) as string | undefined,
      published: formatDateToTimestamp(route.meta.published as string),
      lastmod: formatDateToTimestamp(route.meta.lastmod as string),
      callback_ts: formatDateToTimestamp(new Date()),
    }
  }
  return {
    provide: {
      trackEvent,
    },
  }
})

function getTransactionData(data: Partial<TransactionData>): TransactionData {
  return {
    id: data.id || undefined,
    type: data.type || undefined,
    value: data.value || undefined,
    currency: data.currency || undefined,
    tax: data.tax || undefined,
    discount: data.discount || undefined,
    coupon: data.coupon || undefined,
    item_count: data.item_count || undefined,
    status: data.status || undefined,
  }
}

function getFormDataLayer(data: Partial<FormDataLayer>): FormDataLayer {
  return {
    id: data.id || undefined,
    category: data.category || undefined,
    callback_timestamp: formatDateToTimestamp(new Date()) || undefined,
    field_name: data.field_name || undefined,
    field_validated: data.field_validated || undefined,
    selection: data.selection || undefined,
  }
}

type IssueWithQuantity = Issue & { quantity: number | undefined }

function getItemData(data: Partial<IssueWithQuantity>): ItemData {
  return {
    id: data.id?.toString() || undefined,
    name: data?.name || undefined,
    price: data?.nominalValue?.toString() || undefined,
    currency: 'CZK',
    tax: undefined,
    discount: undefined,
    coupon: undefined,
    quantity: data?.quantity?.toString() || undefined,
    interest: data?.interestRate?.toString() || undefined,
    risk_level: data?.rating?.toString() || undefined,
    maturity: formatDateToTimestamp(data?.maturityDate),
    m_dividend: undefined,
    dividend: undefined,
  }
}
function getClickData(id: string): ClickData {
  return {
    id,
  }
}

function formatString(input: string | undefined): string | undefined {
  if (!input)
    return
  const trimmed = input.trim()
  const formatted = trimmed.toLowerCase().replace(/\s+/g, '_')
  const normalized = formatted.normalize('NFD').replace(/[\u0300-\u036F]/g, '')

  return normalized
}

function formatDateToTimestamp(date: string | Date | undefined) {
  if (!date)
    return undefined
  return new Date(date).getTime().toString()
}
