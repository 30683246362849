import { nextTick, onMounted, ref, watch } from 'vue'
import { useTrackEvent } from '~/composables/useTrackEvent'
import { DataLayerEventsEnum } from '~/types/dataLayer'

export function useCallpageTracking() {
  const trackEvent = useTrackEvent()
  const { setCookie, getCookieParsed } = useCookies()
  const isCallPageOpened = ref(false)

  function openCallpageDialog() {
    (window as any)?.callpage('api.widget.open')
    isCallPageOpened.value = true
  }

  provide('openCallpageDialog', openCallpageDialog)

  watch(isCallPageOpened, async (newValue) => {
    if (!newValue)
      return

    await nextTick()

    const eventMappings = [
      { selector: 'li[data-contact-type="message"]', eventName: 'leave-a-message' },
      { selector: 'li[data-contact-type="schedule"]', eventName: 'call-me-later' },
      { selector: 'li[data-contact-type="call"]', eventName: 'call-me-now' },
    ]

    eventMappings.forEach(({ selector, eventName }) => {
      const element = document.querySelector(selector)
      if (element) {
        element.addEventListener('click', () => {
          trackEvent({
            eventName: DataLayerEventsEnum.PageView,
            pageData: { is_virtual: true, virtual_location: eventName },
          })
        })
      }
    })

    const leaveMessageButton = document.querySelector('button[data-test="leave-message-btn"]')
    if (leaveMessageButton) {
      leaveMessageButton.addEventListener('click', () => {
        trackEvent({ eventName: DataLayerEventsEnum.GenerateLead })
      })
    }
  })

  function setupCallpage() {
    if (!('callpage' in window) || !(window.callpage instanceof Function)) {
      console.error('Callpage is not available')
      return
    }

    const callpage = window.callpage

    callpage('event.onWidgetOpened', () => {
      trackEvent({
        eventName: DataLayerEventsEnum.PageView,
        pageData: { is_virtual: true, virtual_location: 'callpage-open~call-me-now' },
      })
    })

    const callEvents = ['onCallRealTime', 'onCallCreated', 'onCallScheduled']
    callEvents.forEach((event) => {
      callpage(`event.${event}`, (data: any) => {
        const userState = getCookieParsed('userState')
        setCookie('userState', { ...userState, ...(userState === null && { lead_id: `callPage-${data}` }) })
        trackEvent({
          eventName: DataLayerEventsEnum.GenerateLead,
          formData: { id: event, category: 'callPage' },
          pageData: {
            is_virtual: true,
            virtual_location: event === 'onCallScheduled' ? 'callpage-open~on-call-scheduled' : 'callpage-open~call-me-now',
          },
          userInfo: event === 'onCallRealTime' ? { id: callpage('stats.getVisitorId') } : undefined,
        })
      })
    })

    callpage('event.onWidgetClosed', () => {
      isCallPageOpened.value = false
    })
  }

  onMounted(() => {
    setupCallpage()
  })

  return { openCallpageDialog, isCallPageOpened }
}
