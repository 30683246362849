
// Generated by dayjs-nuxt-module
import dayjs from 'dayjs'
import updateLocale from 'dayjs/plugin/updateLocale'

import 'dayjs/locale/cs'
import utc from 'dayjs/plugin/utc'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import quarterOfYear from 'dayjs/plugin/quarterOfYear'
import relativeTime from 'dayjs/plugin/relativeTime'


dayjs.extend(updateLocale)

dayjs.extend(utc)
dayjs.extend(customParseFormat)
dayjs.extend(quarterOfYear)
dayjs.extend(relativeTime)



// defaultLocale: "cs"


dayjs.updateLocale("cs")
dayjs.locale('cs')


export default dayjs
