const wait = (ms: number) => new Promise(resolve => setTimeout(resolve, ms))

const WAIT_TO_NEXT_TRY_MS = 50
const MAX_TRY_COUNT = Array.from({ length: 5 }).fill(null).map((_, i) => i)

export default defineNuxtPlugin({
  parallel: true,

  async setup() {
    const config = useRuntimeConfig()
    let isSpriteLoaded = false

    for await (const i of MAX_TRY_COUNT) {
      try {
        if (isSpriteLoaded) {
          break
        }

        if (i > 0) {
          await wait(WAIT_TO_NEXT_TRY_MS)
        }

        let path: string
        if (config.public.environment === 'dev') {
          path = '/public/sprite.svg'
        }
        else if (config.public.environment === 'local') {
          path = '/sprite.svg'
        }
        else {
          path = '/public/sprite.svg'
        }

        const sprite = await useFetch<Blob>(path)

        if (sprite.data.value) {
          const reader = new FileReader()

          reader.readAsText(sprite.data.value)

          reader.onload = (e) => {
            isSpriteLoaded = true

            const el = document.createElement('div')
            el.classList.add('sprite-container')
            el.innerHTML = (e.target as any).result as string
            document.body.appendChild(el)
          }
        }
      }
      catch (_) {
      // Nothing to do
      }
    }

    // CAPTURE EXCEPTION IF SVG SPRITE WAS NOT LOADED AFTER MAX TRY COUNT
    if (!isSpriteLoaded) {
      const captureException = (await import('@sentry/core')).captureException
      captureException(new Error('SVG sprite was not loaded'))
    }
  },
})
