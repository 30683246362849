export type UserData = {
  user_id: string | undefined
  lead_id: string | undefined
  name: string | undefined
  name_hashed: string | undefined
  phone: string | undefined
  phone_hashed: string | undefined
  email: string | undefined
  email_hashed: string | undefined
  user_address: string | undefined
  clv: number | undefined
  category: string | undefined
  mkt_segment: string | undefined
  legal_limitation: string | undefined
}

export type PageData = {
  location: string | undefined
  title: string | undefined
  author: string | undefined
  published: string | undefined
  lastmod: string | undefined
  category: string | undefined
  language: string | undefined
  is_virtual: boolean | undefined
  virtual_location: string | undefined
  environment: string | undefined
  locale: string | undefined
  callback_ts: string | undefined
}
export type TransactionData = {
  id: string | undefined
  type: string | undefined
  value: string | undefined
  currency: string | undefined
  tax: string | undefined
  discount: string | undefined
  coupon: string | undefined
  item_count: string | undefined
  status: string | undefined
}

export type ItemData = {
  id: string | undefined
  name: string | undefined
  price: string | undefined
  tax: string | undefined
  quantity: string | undefined
  discount: string | undefined
  currency: string | undefined
  coupon: string | undefined
  risk_level: string | undefined
  maturity: string | undefined
  m_dividend: string | undefined
  interest: string | undefined
  dividend: string | undefined
}

export type FormDataLayer = {
  id: string | undefined
  category: string | undefined
  callback_timestamp: string | undefined
  field_name: string | undefined
  field_validated: string | undefined
  selection: string | undefined
}

export type ClickData = {
  id: string | undefined
}

export enum DataLayerEventsEnum {
  PageView = 'page_view',
  Registration = 'registration',
  BeginCheckout = 'begin_checkout',
  CheckoutProgress = 'checkout_progress',
  Purchase = 'purchase',
  GenerateLead = 'generate_lead',
  ViewItem = 'view_item',
}

export const DataLayerFlowSteps = {
  registration: {
    first_step: ' 01~button_click',
    email_step: ' 02~email',
    pass_step: ' 03~password',
    verif_step: ' 04~verification',
    phone_step: '05~phone_number',
    login_step: '06~login',
  },
  checkout: {
    first_step: '01~calculation',
    summary: '02~summary',
    escrow: '03~escrow',
  },
}

export const DataLayerFormCategories = {
  registration: 'registration',
}
