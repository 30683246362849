import { default as _91_46_46_46slug_93OAr6RmbZTAMeta } from "/home/runner/work/dluhopisomat-web-v2/dluhopisomat-web-v2/pages/[...slug].vue?macro=true";
import { default as indexQRgaP4521hMeta } from "/home/runner/work/dluhopisomat-web-v2/dluhopisomat-web-v2/pages/index.vue?macro=true";
import { default as _91id_931MIxUdZbgjMeta } from "/home/runner/work/dluhopisomat-web-v2/dluhopisomat-web-v2/pages/investice/[id].vue?macro=true";
import { default as component_45stubLhd4RM6EfsMeta } from "/home/runner/work/dluhopisomat-web-v2/dluhopisomat-web-v2/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubLhd4RM6Efs } from "/home/runner/work/dluhopisomat-web-v2/dluhopisomat-web-v2/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "slug___cs",
    path: "/:slug(.*)*",
    meta: _91_46_46_46slug_93OAr6RmbZTAMeta || {},
    component: () => import("/home/runner/work/dluhopisomat-web-v2/dluhopisomat-web-v2/pages/[...slug].vue")
  },
  {
    name: "index___cs",
    path: "/",
    component: () => import("/home/runner/work/dluhopisomat-web-v2/dluhopisomat-web-v2/pages/index.vue")
  },
  {
    name: "investice-id___cs",
    path: "/investice/:id()",
    meta: _91id_931MIxUdZbgjMeta || {},
    component: () => import("/home/runner/work/dluhopisomat-web-v2/dluhopisomat-web-v2/pages/investice/[id].vue")
  },
  {
    name: component_45stubLhd4RM6EfsMeta?.name,
    path: "/sitemap.xml",
    component: component_45stubLhd4RM6Efs
  }
]