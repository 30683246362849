import type { MultilinkStoryblok } from '~/types/storyblok'

export function getSBLink(link?: MultilinkStoryblok) {
  if (!link) {
    return ''
  }

  if (link.story) {
    return `${link.story.url}${link.anchor ? `#${link.anchor}` : ''}`
  }

  let linkBase = ''
  if (link.url) {
    linkBase = link.url
  }
  else if (link.cached_url) {
    linkBase = `/${link.cached_url}`
  }
  return `${linkBase}${link.anchor ? `#${link.anchor}` : ''}`
}

/**
 * Returns the size of an image based on its file name.
 * @param imageFileName - The filename property of the Storyblok image asset.
 * @returns The size of the image as an array of numbers [width, height].
 */
export function getSBImageSize(imageFileName: string): [number, number] {
  return (imageFileName?.split('/')?.[5]?.split?.('x').map(Number) as [number, number]) || [0, 0]
}
