import Cookies from 'js-cookie'

export function useCookies() {
  const config = useRuntimeConfig()
  const isProd = config.public.environment === 'production'
  const isDev = config.public.environment === 'dev'

  const cookieOptions = {
    path: '/',
    secure: isProd,
    sameSite: 'Lax' as 'strict' | 'Strict' | 'lax' | 'Lax' | 'none' | 'None' | undefined,
    domain: isProd ? '.dluhopisomat.cz' : isDev ? '.dluhopisomat.cz' : 'localhost',
    expires: new Date(new Date().getTime() + 60 * 60 * 24 * 30 * 1000),
  }
  function setCookie(name: string, data: any) {
    Cookies.set(name, JSON.stringify(data), cookieOptions)
  }
  function getCookieParsed(name: string) {
    const cookieValue = Cookies.get(name)

    if (!cookieValue) {
      return null
    }

    return JSON.parse(cookieValue)
  }

  return {
    setCookie,
    getCookieParsed,
  }
}
