import { useScriptGoogleTagManager, useScript, defineNuxtPlugin } from '#imports'

export default defineNuxtPlugin({
  name: "scripts:init",
  env: { islands: false },
  parallel: true,
  setup() {
    const googleTagManager = useScriptGoogleTagManager({"id":"GTM-M7LVB7HJ"})
    return { provide: { $scripts: { googleTagManager } } }
  }
})